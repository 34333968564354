"use strict";

export default {
    "filters": {
        $_filterFormatAvailability(val) {
            // 有無の表示形式を変更
            if (val == "1") {
                return "あり";
            } else {
                return "なし";
            }
        },
        $_filterFormatPrice(val) {
            // 金額にカンマを追加
            return Number(val).toLocaleString();
        },
        $_filterFormatReceiptInclude(val) {
            // 領収証送り先の表示形式を変更
            if (val == "0") {
                return "お客様情報入力住所へ郵送";
            } else {
                return "商品へ同梱してお届け";
            }
        },
    },
};
