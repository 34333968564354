<template>
    <ValidationObserver class="contents" tag="div" style="background:none;margin:0;">
        <h1 class="pagetitle" style="color:#fff;">商品注文一覧</h1>
        <div v-for="(data, index) in contents.listItems" :key="`list${index}`">
            <div class="item">
                <div class="item__contents--image">
                    <div class="item__contents__block">
                        <img class="item__contents__block__image" :src="`${filesUrl}${data.item_image1}`" alt="">
                    </div>
                </div>
                <div class="item__contents">
                    <h2 class="item__contents__title">
                        {{ data.item_name }}
                    </h2>
                    <p class="item__contents__price">
                        <span class="item__contents__price__tax">税込</span>{{ data.price | $_filterFormatPrice }}円
                    </p>
                    <p class="item__contents__text">
                        {{ data.item_description }}
                    </p>
                    <div class="item__contents__order">
                        <template v-if="String(data.stock) === '1'">
                            <button class="order-minus" @click="minus(data.item_id)">-</button>
                            <ValidationProvider class="order-count" v-slot="{ errors }" :name="`商品${data.item_id}注文数`" rules="min:0|max:99|regex:^([0-9]*)$|required">
                                <input v-model="contents.orderCount[data.item_id]" max="99" min="0" type="number" :style="validatedOrderCount(errors[0], data.item_id)">
                            </ValidationProvider>
                            <button class="order-plus" @click="plus(data.item_id)">+</button>
                        </template>
                        <template v-else>
                            <p>在庫切れ</p>
                        </template>
                    </div>
                    <div v-if="(data.free_text && data.free_text !== 'null') || data.item_image2 || data.item_image3 || data.item_image4 || data.item_image5 || data.item_image6 || data.item_image7" class="item__contents__link">
                        <button :ref="`open${data.item_id}`" class="item__contents__link__button" @click="openedFree(data.item_id)" type="button">
                            詳細を開く
                        </button>
                        <button :ref="`close${data.item_id}`" class="item__contents__link__button" style="display: none;" @click="closedFree(data.item_id)" type="button">
                            詳細を閉じる
                        </button>
                    </div>
                </div>
            </div>
            <div :ref="`free${data.item_id}`" class="item__free" style="display: none;">
                <div :ref="`image${data.item_id}`" class="item__contents__block--flex" style="display: none;">
                    <img v-if="data.item_image2" class="item__contents__block__image--flex" :src="`${filesUrl}${data.item_image2}`" alt="">
                    <img v-if="data.item_image3" class="item__contents__block__image--flex" :src="`${filesUrl}${data.item_image3}`" alt="">
                    <img v-if="data.item_image4" class="item__contents__block__image--flex" :src="`${filesUrl}${data.item_image4}`" alt="">
                    <img v-if="data.item_image5" class="item__contents__block__image--flex" :src="`${filesUrl}${data.item_image5}`" alt="">
                    <img v-if="data.item_image6" class="item__contents__block__image--flex" :src="`${filesUrl}${data.item_image6}`" alt="">
                    <img v-if="data.item_image7" class="item__contents__block__image--flex" :src="`${filesUrl}${data.item_image7}`" alt="">
                </div>
                <div v-if="data.free_text && data.free_text !== 'null'" class="item__free__text">
                    {{ data.free_text }}
                </div>
            </div>
        </div>
        <div class="l-mt7 l-mb3 is-center">
            <button :class="`${classButton}${buttonType}`" type="button" :disabled="validatedButton" @click="goToNext()">
                お客様情報入力へ進む
            </button>
        </div>
    </ValidationObserver>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";
    import confirmSave from "@/mixins/confirmSave";
    import filter from "@/mixins/filter";

    export default {
        "mixins": [
            common,
            confirmSave,
            filter,
        ],
        "props": {
            "contents": {
                "required": true,
                "type": Object,
            },
        },
        data() {
            return {
                "buttonType": "invalid",
                "classButton": "button--oval--",
                "filesUrl": common.$_configs.filesUrl,
            };
        },
        "computed": {
            validatedButton() {
                // お客様情報入力へ進むボタン用バリデーション
                let disabled = false;
                for (let id in this.contents.orderCount) {
                    if (Number(this.contents.orderCount[id])) {
                        this.buttonType = "primary";
                        disabled = false;
                        break;
                    } else {
                        this.buttonType = "invalid";
                        disabled = true;
                    }
                }
                return disabled;
            },
        },
        "methods": {
            validatedOrderCount(error, id = "") {
                // 注文数のバリデーション
                if (error) {
                    if (id) {
                        const count = this.contents.orderCount[id];
                        if (count) {
                            if (count < 0) {
                                this.contents.orderCount[id] = 0;
                            } else if (count > 99) {
                                this.contents.orderCount[id] = 99;
                            } else {
                                this.contents.orderCount[id] = Math.floor(this.contents.orderCount[id]);
                            }
                        }
                    } else {
                        return "background: #fcc";
                    }
                }
            },
            plus(id) {
                // 注文数を増やす
                if(100 <= Number(("0" + this.contents.orderCount[id]).slice(-2)) + 1) {
                    this.contents.orderCount[id] = 99;
                } else {
                    this.contents.orderCount[id] = Number(("0" + this.contents.orderCount[id]).slice(-2)) + 1;
                }
            },
            minus(id) {
                // 注文数を減らす
                if(0 > Number(this.contents.orderCount[id]) - 1) {
                    this.contents.orderCount[id] = 0;
                } else {
                    this.contents.orderCount[id] = Number(this.contents.orderCount[id]) - 1;
                }
            },
            openedFree(id) {
                // 商品詳細を開く
                this.$refs[`open${id}`][0].style.display = "none";
                this.$refs[`close${id}`][0].style.display = "block";
                this.$refs[`free${id}`][0].style.display = "block";
                this.$refs[`image${id}`][0].style.display = "flex";
            },
            closedFree(id) {
                // 商品詳細を閉じる
                this.$refs[`open${id}`][0].style.display = "block";
                this.$refs[`close${id}`][0].style.display = "none";
                this.$refs[`free${id}`][0].style.display = "none";
                this.$refs[`image${id}`][0].style.display = "none";
            },
            setOrders() {
                this.contents.input.order = [];
                // 注文商品をset
                for (let id in this.contents.orderCount) {
                    if (Number(this.contents.orderCount[id])) {
                        const quantity = Number(("0" + this.contents.orderCount[id]).slice(-2));
                        for (let data of this.contents.listItems) {
                            if (id === String(data.item_id)) {
                                this.contents.input.order.push({
                                    "item_id": String(data.item_id),
                                    "item_name": data.item_name,
                                    "quantity": String(quantity),
                                    "price": String(data.price),
                                });
                            }
                        }
                    }
                }
            },
            goToNext() {
                // お客様情報入力へ
                this.setOrders();
                this.$router.push({ "name": "input" });
            },
        },
    };
</script>
